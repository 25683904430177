import React from 'react';
import MetaTags from 'react-meta-tags';

export const convertDriveUrlToPhotoUrl = url => {
  if (url && url.includes('drive.google.com')) {
    return `http://drive.google.com/uc?export=view&id=${url.split('id=')[1]}`;
  } else if (url) {
    return url;
  }

  return null;
};

export const formatAuthors = authors =>
  authors.replace(
    'Ather Sharif',
    '<span style="font-weight:bold">Ather Sharif</span>'
  );

export const formatHomeTitle = title => {
  const words = ['Ather', 'Sharif'];

  words.forEach(word => {
    title = title.replace(word, `<span class="color-theme">${word}</span>`);
  });

  return title;
};

export const getMetaContent = attribute =>
  document.head
    .querySelector('meta[name="' + attribute + '"]')
    .getAttribute('content');

export const updateMetaInfo = (description = '', keywords = []) => {
  description = `${getMetaContent('description')} ${description}`;
  keywords = `${getMetaContent('keywords')}, ${keywords.join(', ')}`;

  return (
    <MetaTags>
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="keywords" content={keywords} />
    </MetaTags>
  );
};
