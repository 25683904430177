import React from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink, SectionTitle } from '../../components';
import { convertDriveUrlToPhotoUrl, updateMetaInfo } from '../../utils';

const description = "Here are some of Ather's projects.";

const keywords = projects => [
  ...projects.map(p => p.title.toLowerCase()),
  'projects'
];

const Projects = ({ db: { projects } }) => {
  const activeProjects = projects.filter(p => p.active === '1');
  const inactiveProjects = projects.filter(p => p.active !== '1');

  return (
    <div className="mi-project mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(projects))}
      <div className="container">
        <SectionTitle title="Projects" />
        <div className="row active mt-30-reverse">
          {orderBy(activeProjects, ['year'], ['desc']).map(project => (
            <div
              className="mi-project col-lg-6 col-md-6 col-12 mt-30"
              key={project.id}
            >
              <DataLink to={'projects/' + project.code}>
                <div
                  className="mi-project-image"
                  style={{
                    backgroundImage: `url('${convertDriveUrlToPhotoUrl(
                      project.image
                    )}')`
                  }}
                >
                  <div className="mi-project-text">
                    <h3>{project.title}</h3>
                    <div className="subtitle">{project.subtitle}</div>
                  </div>
                </div>
              </DataLink>
            </div>
          ))}
        </div>
        <div className="row inactive mt-30-reverse">
          {orderBy(inactiveProjects, ['year'], ['desc']).map(project => (
            <div
              className="mi-project col-lg-4 col-md-4 col-12 mt-30"
              key={project.id}
            >
              <DataLink to={'projects/' + project.code}>
                <div
                  className="mi-project-image"
                  style={{
                    backgroundImage: `url('${convertDriveUrlToPhotoUrl(
                      project.image
                    )}')`
                  }}
                >
                  <div className="mi-project-text">
                    <h3>{project.title}</h3>
                  </div>
                </div>
              </DataLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Projects.propTypes = {
  db: PropTypes.shape({
    projects: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('projects')(Projects);
