import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description = "Here are some of Ather's news contributions.";

const keywords = news => [...news.map(w => w.title.toLowerCase()), ...['news']];

const News = ({ db: { news } }) => {
  const years = uniq(news.map(w => w.year))
    .sort()
    .reverse();

  let previousYear = null;

  return (
    <div className="mi-news-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(news))}
      <div className="container">
        <SectionTitle title="News" />
        <div className="mi-news-wrapper">
          {years.map(year =>
            orderBy(
              news
                .filter(w => w.year === year)
                .map(w => ({ ...w, id: parseInt(w.id) })),
              ['id'],
              ['desc']
            ).map(s => {
              let date = `${s.month}/${s.year}`;

              if (date === previousYear) date = '';
              else previousYear = date;

              return (
                <div key={s.id} className="mi-news mt-30">
                  <div className="mi-news-summary">
                    <p className="mi-news-year">{date}</p>
                  </div>
                  <div className="mi-news-details">
                    <h2>
                      <p dangerouslySetInnerHTML={{ __html: s.title }} />
                    </h2>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

News.propTypes = {
  db: PropTypes.shape({
    news: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('news')(News);
