import React from 'react';
import { Switch, Route } from 'react-router';
import ReactGA from 'react-ga4';
import {
  About,
  Awards,
  Contact,
  Home,
  Media,
  News,
  NoMatch,
  ProjectDetails,
  Projects,
  Publications,
  Service,
  Writing
} from './views';

const componentWithGA = Component => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'Ather Sharif Research Portfolio'
  });

  return <Component />;
};

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => componentWithGA(Home)} />
    <Route exact path="/about" render={() => componentWithGA(About)} />
    <Route exact path="/awards" render={() => componentWithGA(Awards)} />
    <Route exact path="/contact" render={() => componentWithGA(Contact)} />
    <Route exact path="/media" render={() => componentWithGA(Media)} />
    <Route exact path="/news" render={() => componentWithGA(News)} />
    <Route
      exact
      path="/projects/:projectid"
      render={() => componentWithGA(ProjectDetails)}
    />
    <Route exact path="/projects" render={() => componentWithGA(Projects)} />
    <Route
      exact
      path="/publications"
      render={() => componentWithGA(Publications)}
    />
    <Route exact path="/service" render={() => componentWithGA(Service)} />
    <Route exact path="/writing" render={() => componentWithGA(Writing)} />
    <Route component={NoMatch} />
  </Switch>
);

export default Routes;
