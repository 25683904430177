import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description =
  'Here are some of the awards and achievements that Ather has received.';

const keywords = awards => [
  ...awards.map(a => a.award.toLowerCase()),
  ...['awards', 'accolades', 'achievements', 'accomplishments']
];

const Awards = ({ db: { awards } }) => {
  const years = uniq(awards.map(w => w.year))
    .sort()
    .reverse();

  return (
    <div className="mi-award-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(awards))}
      <div className="container">
        <SectionTitle title="awards" />
        <div className="mi-award-wrapper">
          {years.map(year =>
            orderBy(
              awards.filter(a => a.year === year),
              ['id'],
              ['desc']
            ).map((award, index) => (
              <div key={award.id} className="mi-award mt-30">
                <div className="mi-award-summary">
                  <p className="mi-award-year">{index === 0 ? year : ''}</p>
                </div>
                <div className="mi-award-details">
                  <h2>
                    {award.url && award.url !== '' ? (
                      <DataLink to={award.url}>{award.award}</DataLink>
                    ) : (
                      <span>{award.award}</span>
                    )}{' '}
                    at <span className="venue">{award.venue}</span>
                  </h2>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Awards.propTypes = {
  db: PropTypes.shape({
    awards: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('awards')(Awards);
