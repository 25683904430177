import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description =
  'Here are some of the writing pieces that Ather has written.';

const keywords = writings => [
  ...writings.map(w => w.title.toLowerCase()),
  ...['writing', 'article']
];

const Writing = ({ db: { writings } }) => {
  const years = uniq(writings.map(w => w.year))
    .sort()
    .reverse();

  return (
    <div className="mi-writing-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(writings))}
      <div className="container">
        <SectionTitle title="Writing" />
        <div className="mi-writing-wrapper">
          {years.map(year =>
            orderBy(
              writings.filter(w => w.year === year),
              ['id'],
              ['desc']
            ).map((writing, index) => (
              <div key={writing.id} className="mi-writing mt-30">
                <div className="mi-writing-summary">
                  <p className="mi-writing-year">{index === 0 ? year : ''}</p>
                </div>
                <div className="mi-writing-details">
                  <h2>
                    <DataLink to={writing.url}>{writing.title}</DataLink>
                  </h2>
                  <p className="venue">{writing.venue}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Writing.propTypes = {
  db: PropTypes.shape({
    writings: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('writings')(Writing);
