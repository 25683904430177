import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description =
  'Here are some of the media coverage that involves Ather and his work.';

const keywords = mediaCoverages => [
  ...mediaCoverages.map(w => w.title.toLowerCase()),
  ...['media', 'coverage', 'article']
];

export const getMediaCoveragesList = (
  media,
  projects,
  displayProjects = true
) => {
  const years = uniq(media.map(w => w.year))
    .sort()
    .reverse();

  return years.map(year =>
    orderBy(
      media.filter(w => w.year === year),
      ['id'],
      ['desc']
    ).map((m, index) => {
      const mediaProjects = m.projects
        ? m.projects.split(',').map((mp, i) => {
            const p = projects.find(r => r.code === mp.trim());

            return (
              <DataLink to={'/projects/' + p.code} key={i}>
                {p.title}
              </DataLink>
            );
          })
        : undefined;

      return (
        <div key={m.id} className="mi-media mt-30">
          <div className="mi-media-summary">
            <p className="mi-media-year">{index === 0 ? year : ''}</p>
          </div>
          <div className="mi-media-details">
            <h2>
              <DataLink to={m.url}>{m.title}</DataLink>
            </h2>
            <p className="venue">
              By <strong>{m.venue}</strong>
            </p>
            {mediaProjects && displayProjects && (
              <p className="projects">Projects: {mediaProjects}</p>
            )}
          </div>
        </div>
      );
    })
  );
};

const Media = ({ db: { media, projects } }) => {
  return (
    <div className="mi-media-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(media))}
      <div className="container">
        <SectionTitle title="Media Coverage" />
        <div className="mi-media-wrapper">
          {getMediaCoveragesList(media, projects)}
        </div>
      </div>
    </div>
  );
};

Media.propTypes = {
  db: PropTypes.shape({
    media: PropTypes.arrayOf(PropTypes.object),
    projects: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets(['media', 'projects'])(Media);
