import React from 'react';
import { Particles as P } from 'react-particles-js';

const params = {
  particles: {
    number: {
      value: 160,
      density: {
        enable: false
      }
    },
    color: {
      value: '#ffffff'
    },
    opacity: {
      value: 0.1
    },
    size: {
      value: 5,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3
      }
    },
    line_linked: {
      enable: false
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out'
    }
  }
};

const Particles = () => <P className="mi-home-particle" params={params} />;

export default Particles;
