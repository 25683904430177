import React from 'react';

const BackgroundLines = () => (
  <div className="mi-bglines">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default BackgroundLines;
