import React from 'react';
import PropTypes from 'prop-types';
import { Link, Video } from 'react-feather';
import flowRight from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import { withGoogleSheets } from 'react-db-google-sheets';
import { getMediaCoveragesList } from '../Media';
import { getPublicationsList } from '../Publications';
import { DataLink, SectionTitle } from '../../components';
import { convertDriveUrlToPhotoUrl, updateMetaInfo } from '../../utils';

const Projects = ({
  db: { media, projects, publications },
  match: { params }
}) => {
  const mediaCoverages = media.filter(m =>
    m.projects
      ? m.projects
          .split(',')
          .map(p => p.trim())
          .includes(params.projectid)
      : false
  );
  const project = projects.find(p => p.code === params.projectid);
  const projectPublications = publications.filter(
    p => p.project === params.projectid
  );
  const description = 'Details on ' + project.title;

  return (
    <div className="mi-project mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, [project.title, 'projects'])}
      <div className="container">
        <SectionTitle title={project.title} />
        <div className="row mt-30-reverse">
          <div
            className="mi-project col-lg-6 col-md-6 col-12 mt-30"
            key={project.id}
          >
            <div
              className="mi-project-image"
              style={{
                backgroundImage: `url('${convertDriveUrlToPhotoUrl(
                  project.image
                )}')`
              }}
            />
            <div className="icons">
              {project.url && (
                <DataLink to={project.url}>
                  <Link />
                </DataLink>
              )}
              {project.video && (
                <DataLink to={project.video}>
                  <Video />
                </DataLink>
              )}
            </div>
          </div>
        </div>
        {projectPublications.length > 0 && (
          <div>
            <h2>Publications</h2>
            {getPublicationsList(projectPublications)}
          </div>
        )}
        {mediaCoverages.length > 0 && (
          <div>
            <h2>Media Coverage</h2>
            {getMediaCoveragesList(mediaCoverages, projects, false)}
          </div>
        )}
      </div>
    </div>
  );
};

Projects.propTypes = {
  db: PropTypes.shape({
    media: PropTypes.arrayOf(PropTypes.object),
    projects: PropTypes.arrayOf(PropTypes.object),
    publications: PropTypes.arrayOf(PropTypes.object)
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectid: PropTypes.string.isRequired
    })
  })
};

export default flowRight(
  withRouter,
  withGoogleSheets(['projects', 'publications', 'media'])
)(Projects);
