import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import Footer from './Footer';
import { convertDriveUrlToPhotoUrl } from '../../utils';

const Sidebar = ({ db: { home: data }, toggler }) => {
  const headshot = data.find(d => d.key === 'headshot').value;
  const headshotAlt = data.find(d => d.key === 'headshot_alt').value;

  return (
    <div className="mi-header-inner">
      <div className="mi-header-image">
        <Link to="/">
          <img src={convertDriveUrlToPhotoUrl(headshot)} alt={headshotAlt} />
        </Link>
      </div>
      <ul className="mi-header-menu" onClick={toggler}>
        <li>
          <NavLink exact to="/">
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/about">
            <span>About</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/publications">
            <span>Publications</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/projects">
            <span>Projects</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/awards">
            <span>Awards</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/news">
            <span>News</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/media">
            <span>Media Coverage</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/service">
            <span>Service</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/writing">
            <span>Writing</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact">
            <span>Contact</span>
          </NavLink>
        </li>
      </ul>
      <Footer />
    </div>
  );
};

Sidebar.propTypes = {
  db: PropTypes.shape({
    home: PropTypes.arrayOf(PropTypes.object)
  }),
  toggler: PropTypes.func
};

export default withGoogleSheets('home')(Sidebar);
