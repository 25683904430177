import React, { useState } from 'react';
import { Menu, X } from 'react-feather';
import Sidebar from './Sidebar';

const Header = () => {
  const [navigationToggler, setNavigationToggler] = useState(false);

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  return (
    <nav className={navigationToggler ? 'mi-header is-visible' : 'mi-header'}>
      <button onClick={handleNavigationToggler} className="mi-header-toggler">
        {!navigationToggler ? <Menu /> : <X />}
      </button>
      <Sidebar toggler={handleNavigationToggler} />
    </nav>
  );
};

export default Header;
