import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GoogleSheetsProvider from 'react-db-google-sheets';
import ReactGA from 'react-ga4';
import Routes from './Routes';
import { Layout, Navigation } from './components';

import './App.scss';

class App extends Component {
  componentDidMount() {
    ReactGA.initialize('G-M2CR6J7CWN');
  }

  render() {
    return (
      <GoogleSheetsProvider>
        <Router>
          <Navigation />
          <Layout>
            <Routes />
          </Layout>
        </Router>
      </GoogleSheetsProvider>
    );
  }
}

export default App;
