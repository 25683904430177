import React from 'react';

const NoMatch = () => (
  <div className="mi-home-area mi-padding-section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-12">
          <div className="mi-home-content">
            <h1>404 Not Found</h1>
            <p>Page not found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NoMatch;
