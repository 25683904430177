import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { SectionTitle } from '../../components';
import { convertDriveUrlToPhotoUrl, updateMetaInfo } from '../../utils';

const description = 'Ather is advised by Jacob Wobbrock.';

const keywords = ['about', 'evoxlabs', 'comcast', 'jacob wobbrock'];

const About = ({ db: { about: data } }) => {
  const text = data.find(d => d.key === 'text').value;
  const image = data.find(d => d.key === 'image').value;
  const imageAlt = data.find(d => d.key === 'image_alt').value;

  return (
    <div className="mi-about-area mi-section mi-padding-top">
      {updateMetaInfo(description, keywords)}
      <div className="container">
        <SectionTitle title="About Me" />
        <div className="row">
          <div className="col-lg-6">
            <div className="mi-about-image">
              <img src={convertDriveUrlToPhotoUrl(image)} alt={imageAlt} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mi-about-content">
              <p dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  db: PropTypes.shape({
    about: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('about')(About);
