import React from 'react';
import PropTypes from 'prop-types';
import Particles from './Particles';
import BackgroundLines from './BackgroundLines';

const Layout = ({ children }) => (
  <div className="mi-wrapper">
    <BackgroundLines />
    <Particles />
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
