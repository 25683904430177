import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink } from '../../components';
import { formatHomeTitle } from '../../utils';

const Home = ({ db: { home: data, upcomingtravel } }) => {
  const title = data.find(d => d.key === 'title').value;
  const intro = data.find(d => d.key === 'intro').value;
  const travels = upcomingtravel.filter(u => u.done === '0');

  return (
    <div className="mi-home-area mi-padding-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-12">
            <div className="mi-home-content">
              <h1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatHomeTitle(title)
                  }}
                />
              </h1>
              <p dangerouslySetInnerHTML={{ __html: intro }} />
              <div className="color-theme">
                <DataLink to="/about">Interested in knowing more?</DataLink>
                &nbsp;|&nbsp;
                <DataLink to="/news">Latest news</DataLink>
              </div>
              <div className="upcoming-travel">
                <span className="title">Upcoming Travel: </span>
                {travels.map((travel, index) => (
                  <span key={travel.id}>
                    {index !== 0 && <span className="color-theme"> | </span>}
                    <span className="event-name">
                      <DataLink to={travel.event_url}>{travel.event}</DataLink>
                    </span>
                    {`, ${travel.city} - ${travel.date} (${travel.reasoning})`}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  db: PropTypes.shape({
    home: PropTypes.arrayOf(PropTypes.object),
    upcomingtravel: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets(['home', 'upcomingtravel'])(Home);
