import React from 'react';
import { GitHub, File, FileText } from 'react-feather';
import DataLink from './DataLink';

const Footer = () => (
  <ul className="mi-socialicons mi-socialicons-bordered">
    <li>
      <DataLink to="https://github.com/athersharif">
        <GitHub />
      </DataLink>
    </li>
    <li>
      <DataLink to="https://athersharif.me/documents/Ather_Sharif_CV.pdf">
        <FileText />
      </DataLink>
    </li>
    <li>
      <DataLink to="https://athersharif.me/documents/Ather_Sharif_Resume.pdf">
        <File />
      </DataLink>
    </li>
  </ul>
);

export default Footer;
