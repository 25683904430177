import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { Airplay, Award, FileText, Link } from 'react-feather';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { DataLink, SectionTitle } from '../../components';
import { formatAuthors, updateMetaInfo } from '../../utils';

const description = "Here is the list of Ather's publications.";

const keywords = publications => [
  ...publications.map(p => p.title.toLowerCase()),
  ...['publications', 'conference', 'journal', 'workshop']
];

export const getPublicationsList = publications => {
  const years = uniq(publications.map(w => w.year))
    .sort()
    .reverse();

  return years.map(year =>
    orderBy(
      publications.filter(w => w.year === year),
      ['id'],
      ['desc']
    ).map((publication, index) => {
      const isBestArtifact = publication.best_artifact === '1';
      const isBestPaper = publication.best_paper === '1';
      const isBestPaperNominee =
        publication.best_paper !== '1' &&
        publication.best_paper_nominee === '1';

      return (
        <div key={publication.id} className="mi-publication mt-30">
          <div className="mi-publication-summary">
            <p className="mi-publication-year">{index === 0 ? year : ''}</p>
          </div>
          <div className="mi-publication-details" key={publication.id}>
            <h2>
              <DataLink to={publication.url || '#'}>
                {isBestArtifact && (
                  <span className="best-paper">
                    <Award /> [Best Artifact Award]
                  </span>
                )}
                {isBestPaper && (
                  <span className="best-paper">
                    <Award /> [Best Paper Award]
                  </span>
                )}
                {isBestPaperNominee && (
                  <span className="best-paper">
                    <Award /> [Best Paper Nominee]
                  </span>
                )}
                {`${publication.title} (${publication.conference}'${publication.year})`}
              </DataLink>
            </h2>
            <h3 className="mi-publication-authors">
              <span
                dangerouslySetInnerHTML={{
                  __html: formatAuthors(publication.authors)
                }}
              />
            </h3>
            <div className="mi-publication-details-icons">
              {publication.url && (
                <DataLink to={publication.url || '#'}>
                  <span className="link-icon">
                    <FileText />
                  </span>
                </DataLink>
              )}
              {publication.web_url && (
                <DataLink to={publication.web_url || '#'}>
                  <span className="link-icon">
                    <Link />
                  </span>
                </DataLink>
              )}
              {publication.presentation && (
                <DataLink to={publication.presentation}>
                  <span className="link-icon">
                    <Airplay />
                  </span>
                </DataLink>
              )}
            </div>
            <p className="citation">{publication.citation}</p>
          </div>
        </div>
      );
    })
  );
};

const Publications = ({ db: { publications } }) => {
  return (
    <div className="mi-publication-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(publications))}
      <div className="container">
        <SectionTitle title="Publications" />
        <div className="mi-publication-wrapper">
          {getPublicationsList(publications)}
        </div>
      </div>
    </div>
  );
};

Publications.propTypes = {
  db: PropTypes.shape({
    publications: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('publications')(Publications);
