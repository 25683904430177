import React from 'react';
import {
  AtSign,
  Mail,
  Voicemail,
  Twitter,
  Instagram,
  Linkedin
} from 'react-feather';
import { DataLink, SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description = 'Here is the contact information for Ather';

const keywords = [
  'contact',
  'phone',
  'email',
  'twitter',
  'instagram',
  'website'
];

const Contact = () => (
  <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
    {updateMetaInfo(description, keywords)}
    <div className="container">
      <SectionTitle title="Contact Me" />
      <div className="row">
        <div className="col-lg-12">
          <h2>The &quot;Professional&quot; Way</h2>
          <div className="mi-contact-info row">
            <div className="mi-contact-infoblock col-md-4 col-sm-12">
              <div className="mi-contact-infoblock-icon">
                <AtSign />
              </div>
              <div className="mi-contact-infoblock-content">
                <p>
                  <DataLink to="mailto:ather@cs.washington.edu">
                    ather at cs.washington.edu
                  </DataLink>
                </p>
                <p className="note">
                  I do own other email addresses but each serves its own
                  purpose. I strongly prefer this email for all research related
                  queries.
                </p>
              </div>
            </div>
            <div className="mi-contact-infoblock col-md-4 col-sm-12 address">
              <span className="mi-contact-infoblock-icon">
                <Mail />
              </span>
              <div className="mi-contact-infoblock-content">
                <p>
                  Paul G. Allen School of Computer Science &amp; Engineering,
                  University of Washington <br />
                  185 E Stevens Way NE, Box 352350 <br />
                  Seattle, WA 98195-2350
                </p>
              </div>
            </div>
            <div className="mi-contact-infoblock col-md-4 col-sm-12">
              <span className="mi-contact-infoblock-icon">
                <Voicemail />
              </span>
              <div className="mi-contact-infoblock-content">
                <p>
                  <DataLink to="tel:1-267-788-0898">+1 267-788-0898</DataLink>
                </p>
                <p className="note">
                  I usually never pick up calls from an unknown number. Please
                  leave a voicemail. Currently on Pacific Time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <h2>The &quot;Millenial&quot; Way</h2>
          <div className="mi-contact-info row">
            <div className="mi-contact-infoblock col-md-4 col-sm-12">
              <div className="mi-contact-infoblock-icon">
                <Twitter />
              </div>
              <div className="mi-contact-infoblock-content">
                <p>
                  <DataLink to="https://twitter.com/athersharif">
                    athersharif
                  </DataLink>
                </p>
              </div>
            </div>
            <div className="mi-contact-infoblock col-md-4 col-sm-12">
              <span className="mi-contact-infoblock-icon">
                <Linkedin />
              </span>
              <div className="mi-contact-infoblock-content">
                <p>
                  <DataLink to="https://www.linkedin.com/in/athersharif/">
                    athersharif
                  </DataLink>
                </p>
              </div>
            </div>
            <div className="mi-contact-infoblock col-md-4 col-sm-12">
              <span className="mi-contact-infoblock-icon">
                <Instagram />
              </span>
              <div className="mi-contact-infoblock-content">
                <p>
                  <DataLink to="https://www.instagram.com/1000001_01110011/">
                    1000001_01110011
                  </DataLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
