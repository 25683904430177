import React from 'react';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';
import { SectionTitle } from '../../components';
import { updateMetaInfo } from '../../utils';

const description = "Here are some of Ather's service contributions.";

const keywords = service => [
  ...service.map(w => w.title.toLowerCase()),
  ...['service']
];

const Service = ({ db: { service } }) => {
  const years = uniq(service.map(w => w.year))
    .sort()
    .reverse();

  return (
    <div className="mi-service-area mi-section mi-padding-top mi-padding-bottom">
      {updateMetaInfo(description, keywords(service))}
      <div className="container">
        <SectionTitle title="Service" />
        <div className="mi-service-wrapper">
          {years.map(year =>
            orderBy(
              service.filter(w => w.year === year),
              ['id'],
              ['desc']
            ).map((s, index) => (
              <div key={s.id} className="mi-service mt-30">
                <div className="mi-service-summary">
                  <p className="mi-service-year">{index === 0 ? year : ''}</p>
                </div>
                <div className="mi-service-details">
                  <h2>
                    {s.title}
                    <p className="organization">{s.organization}</p>
                  </h2>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Service.propTypes = {
  db: PropTypes.shape({
    service: PropTypes.arrayOf(PropTypes.object)
  })
};

export default withGoogleSheets('service')(Service);
